.home-container1 {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-image1 {
  width: 214px;
  height: 44px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link1 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link2 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link3 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link4 {
  text-decoration: none;
}
.home-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-icon10 {
  width: 36px;
  height: 36px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image2 {
  width: 50px;
  object-fit: cover;
}
.home-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon12 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links-container2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link8 {
  text-decoration: none;
}
.home-cta-btn2 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-social-links-container1 {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon16 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon18 {
  width: 24px;
  height: 24px;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text10 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn3 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-image3 {
  width: 50%;
  object-fit: contain;
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text11 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text12 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-cards-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-feature-card1 {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image4 {
  width: 286px;
  height: 125px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text13 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image5 {
  width: 206px;
  height: 126px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text14 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-feature-card3 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image6 {
  width: 262px;
  height: 124px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text15 {
  color: var(--dl-color-gray-500);
  text-align: center;
}
.home-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text16 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text17 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text20 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text21 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card3 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content3 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text24 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text25 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card4 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content4 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text28 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text29 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-about-us {
  display: none;
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-heading-container2 {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text32 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-secondary-text {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn4 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text33 {
  color: var(--dl-color-gray-500);
}
.home-text34 {
  color: var(--dl-color-gray-500);
}
.home-cta-btn5 {
  color: var(--dl-color-gray-white);
  width: 80%;
  display: none;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-testimonials {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-text35 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cards-container2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-testimonial-card1 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position1 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position1 {
  color: var(--dl-color-gray-700);
}
.home-stars-container1 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon20 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon22 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon24 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon26 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon28 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-text36 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-testimonial-card2 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position2 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position2 {
  color: var(--dl-color-gray-700);
}
.home-stars-container2 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon30 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon32 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon34 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon36 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon38 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}
.home-text37 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-testimonial-card3 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position3 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position3 {
  color: var(--dl-color-gray-700);
}
.home-stars-container3 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon40 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon42 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon44 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon46 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon48 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}
.home-text38 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text39 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-location1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-heading5 {
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-adress1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon50 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-email1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon52 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-phone1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon54 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-location2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading6 {
  font-size: 24px;
  font-style: normal;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-adress2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon56 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-email2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon58 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-phone2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon60 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-social-links-container2 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon62 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon64 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon66 {
  width: 24px;
  height: 24px;
}
.home-image7 {
  width: 577px;
  height: 459px;
  margin: var(--dl-space-space-fourunits);
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .home-cta-btn3 {
    width: 40%;
  }
  .home-image3 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card1 {
    align-self: center;
  }
  .home-about-us {
    flex-direction: column;
  }
  .home-heading-container2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-cta-btn4 {
    width: 40%;
    display: none;
  }
  .home-text-container {
    width: 100%;
  }
  .home-cta-btn5 {
    width: 280px;
    display: block;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .home-locations-container {
    flex-wrap: wrap;
  }
  .home-location1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-image7 {
    width: 333px;
    height: 332px;
    margin: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .home-links-container1 {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon10 {
    fill: var(--dl-color-gray-white);
  }
  .home-heading1 {
    text-align: center;
  }
  .home-text10 {
    text-align: center;
  }
  .home-cta-btn3 {
    width: auto;
    align-self: center;
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-cards-container1 {
    align-items: center;
    flex-direction: column;
  }
  .home-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card3 {
    width: 70%;
  }
  .home-card-content1 {
    margin: 0px;
  }
  .home-card-content2 {
    margin: 0px;
  }
  .home-card-content3 {
    margin: 0px;
  }
  .home-card-content4 {
    margin: 0px;
  }
  .home-about-us {
    align-items: center;
  }
  .home-heading-container2 {
    align-items: center;
  }
  .home-text32 {
    text-align: center;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-text35 {
    text-align: center;
  }
  .home-cards-container2 {
    align-items: center;
    flex-direction: column;
  }
  .home-testimonial-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container1 {
    justify-content: flex-end;
  }
  .home-testimonial-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container2 {
    justify-content: flex-end;
  }
  .home-testimonial-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container3 {
    justify-content: flex-end;
  }
  .home-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-content-container {
    width: 100%;
  }
  .home-image7 {
    width: 712px;
    height: 537px;
  }
}
@media(max-width: 479px) {
  .home-image1 {
    border-radius: var(--dl-radius-radius-radius4);
  }
  .home-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-cta-btn2 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-image3 {
    width: 100%;
  }
  .home-feature-card1 {
    width: 100%;
  }
  .home-image4 {
    width: 385px;
    height: 161px;
  }
  .home-feature-card2 {
    width: 100%;
  }
  .home-image5 {
    width: 393px;
    height: 216px;
  }
  .home-feature-card3 {
    width: 100%;
  }
  .home-image6 {
    width: 381px;
    height: 179px;
  }
  .home-service-card1 {
    width: 100%;
  }
  .home-service-card2 {
    width: 100%;
  }
  .home-service-card3 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card4 {
    width: 100%;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-text35 {
    text-align: center;
  }
  .home-testimonial-card1 {
    width: 100%;
  }
  .home-testimonial-card2 {
    width: 100%;
  }
  .home-testimonial-card3 {
    width: 100%;
  }
  .home-content-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text39 {
    text-align: center;
  }
  .home-locations-container {
    margin-bottom: 0px;
  }
  .home-location1 {
    margin-right: 0px;
  }
  .home-social-links-container2 {
    align-self: center;
  }
  .home-image7 {
    width: 374px;
    height: 321px;
  }
}
